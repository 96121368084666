import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | FAQ - Академия кикбоксинга Health Freaks
			</title>
			<meta name={"description"} content={"У вас есть вопросы о кикбоксингах или о присоединении к академии Kickboxing Health Freaks?"} />
			<meta property={"og:title"} content={"FAQ | FAQ - Академия кикбоксинга Health Freaks"} />
			<meta property={"og:description"} content={"У вас есть вопросы о кикбоксингах или о присоединении к академии Kickboxing Health Freaks?"} />
			<meta property={"og:image"} content={"https://solivareber.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solivareber.com/img/boxing.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solivareber.com/img/boxing.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solivareber.com/img/boxing.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 50px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				Часто задаваемые вопросы
			</Text>
			<Text margin="0px 50px 50px 50px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f" text-align="center">
				У вас есть вопросы о кикбоксингах или о присоединении к академии Kickboxing Health Freaks?{" "}
				<br />
				Вот ответы на некоторые из наиболее часто задаваемых вопросов наших потенциальных и нынешних участников.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Кто может присоединиться к Health Freaks?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Наша академия приветствует людей всех уровней квалификации, от начинающих до продвинутых практикующих, с акцентом на обучение, ориентированное на мужчину.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Что мне принести в свой первый класс?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Удобная тренировочная одежда и бутылка с водой необходимы.Все остальное оборудование, включая перчатки и прокладки, будут предоставлены.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Требуется ли предыдущий опыт боевых искусств?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Нет, предыдущий опыт не нужен.Наши классы для начинающих предназначены для представления основы кикбоксинга новым студентам.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Как часто я должен посещать занятия, чтобы увидеть прогресс?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Последовательность является ключом к кикбоксингу.Мы рекомендуем посещать занятия 2–3 раза в неделю, чтобы увидеть постоянный прогресс, но в конечном итоге это зависит от ваших личных целей.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Есть ли спарринг -сессии?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Да, мы предлагаем контролируемые сеансы спарринг для студентов, которые готовы и желают.Предоставлено безопасное снаряжение, и наши тренеры внимательно контролируются сеансы.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Как подписаться на занятия или личное обучение?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Вы можете зарегистрироваться, связавшись с нами напрямую по телефону или по электронной почте.Наша команда проведет вас через процесс и порекомендует лучшую программу для ваших нужд.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});